import * as React from "react"
import "./who.scss"

export default function WhoWeAre({ title, name, description }) {
  return (
    <>
      <section className="who-we-are">
        <div className="container">
          <div className="col-group">
            <div className="col-dt-8 left-col">
              <div className="small-title">{title}</div>
              <div className="big-title h2">{name}</div>
            </div>
            <div className="col-dt-4 right-col">
              <div className="about-us-desc">{description}</div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
