import React, { useState, useContext } from "react"
import "./getIn.scss"
import {useStaticQuery, graphql, navigate } from "gatsby"
import Cf7FormWrapper from "../cf7-form-wrapper"
import { createMarkup, string_translation } from "../../../utils"
import { TranslateUrlsContext } from "gatsby-plugin-translate-urls"
import attentionIcon from "../../../static/images/error-pages/attention-icon.svg"
import countries from "../../../static/countries"

function Form({ handler, isLoading, isSent, hasError, validationErrors, stringTranslations,data }) {
  const [formState, setFormState] = useState({})
  let roles=[
    {
      "en":"Owner",
      "ar":"مالك"
    },
    {
      "en":"Engineering Office / Architect",
      "ar":"مكتب هندسي / معماري"
    },
    {
      "en":"Potential Buyer",
      "ar":"مشتري محتمل"
    },
    {
      "en":"Agency / Agent",
      "ar":"وكالة / وكيل"
    },
    {
      "en":"Other",
      "ar":"أخرى"
    }


  ];

  const handleFieldChange = (field, e) => {
    setFormState({
      ...formState,
      [field]: e.target.value,
    })
  }

  const handleFormSubmit = (e) => {
    handler(e, formState)
  }

  const {
    locale, // ar
  } = useContext(TranslateUrlsContext)

  const ErrorMessages = (errors) => {
    return (
      <div className="errorMessageContainer">
        <div className="errorTitleContainer">
          <img src={attentionIcon} className="attentionIcon" alt={string_translation(stringTranslations, "submission_error", locale)} />
          <span className="errorMessage">{string_translation(stringTranslations, "submission_error", locale)}</span>
        </div>
        <div className="errorBody">
        <p>{errors.errors}</p>
          <span className="tryAgain">{string_translation(stringTranslations, "try_again", locale)}</span>
        </div>
      </div>
    );
  }
  if(isSent) {
    navigate("/"+locale+'/success')
  }

  return (
    <form
      onSubmit={handleFormSubmit}
    >
      {/* <div>isLoading: {isLoading ? "Loading" : "false"}</div>
      <div>isSent: {isSent ? "Sent" : "false"}</div>
      <div>Error: {hasError || "null"}</div> */}

      <div className="errorsParent">{validationErrors ? <ErrorMessages errors={validationErrors} /> : ""}</div>

      <div className="get-in-touch-form">
        
        <div className="all-labels-wrappers act-reg">
          <div className="label-wrapper">
          <div className="col-group">
          {data?.activityRegister?.label?.name &&
              <div className="col-dt-4 label-1">
           
                <input
                  type="text"
                  placeholder={data?.activityRegister?.label?.name}
                  // value={firstname}
                  autoComplete="name"
                  onChange={(e) => handleFieldChange("new-name", e)}
                />
                
              </div>
}
{data?.activityRegister?.label?.phone &&
              <div className="col-dt-4 label-1">
             
                <input
                  type="text"
                  placeholder={data?.activityRegister?.label?.phone}
                  // value={firstname}
                  autoComplete="phone"
                  onChange={(e) => handleFieldChange("phone", e)}
                />
                
              </div>
}
{data?.activityRegister?.label?.email &&
              <div className="col-dt-4 label-1">
            
                <input
                  type="email"
                  placeholder={data?.activityRegister?.label?.email}
                  autoComplete="email"
                  onChange={(e) => handleFieldChange("email", e)}
                />
              
              </div>
}
            </div>
          </div>
          <div className="label-wrapper">
            <div className="col-group">
            {data?.activityRegister?.label?.eventDay &&
              <div className="col-dt-4 label-1">
             
              <div className="select-wrapper">
                  <select
                    // value={countryValue}
                    onChange={(e) => handleFieldChange("event_day", e)}
                  >
                    <option value="" disabled selected>
                    {data?.activityRegister?.label?.eventDay}
                    </option>
                    {data?.activityRegister?.label?.eventDayList?.map((eventDay, i) => {     
            
           return (<option >  {eventDay.eventDay} </option>) 
        })}
                    
                  </select>
                </div>
                
              </div>
}

{data?.activityRegister?.label?.numberOfPerson &&
              <div className="col-dt-4 label-1">
            
                <div className="select-wrapper">
                  <select
                    // value={countryValue}
                    onChange={(e) => handleFieldChange("number_of_person", e)}
                  >
                    <option value="" disabled selected>
                    {data?.activityRegister?.label?.numberOfPerson}
                    </option>
                    {data?.activityRegister?.label?.numberOfPersonList?.map((numberOfPerson, i) => {     
            
            return (<option >  {numberOfPerson.numberOfPerson} </option>) 
         })}
                  </select>
                </div>

              </div>
}
              <div className="col-dt-4 label-0">
               &nbsp;
              </div>
            </div>
          </div>
          <div className="label-wrapper">
            <div className="col-group">
            {data?.activityRegister?.label?.firstName &&
              <div className="col-dt-4 label-1">
              
                <input
                  type="text"
                  placeholder={data?.activityRegister?.label?.firstName}
                  // value={firstname}
                 
                  onChange={(e) => handleFieldChange("first_name", e)}
                />
              
              </div>
}
{data?.activityRegister?.label?.secondName &&
              <div className="col-dt-4 label-1">
          
                <input
                  type="text"
                  placeholder={data?.activityRegister?.label?.secondName}
                  // value={firstname}
                 
                  onChange={(e) => handleFieldChange("second_name", e)}
                />
              
              </div>
}

{data?.activityRegister?.label?.thirdName &&
              <div className="col-dt-4 label-1">
            
                <input
                  type="text"
                  placeholder={data?.activityRegister?.label?.thirdName}
               
                  onChange={(e) => handleFieldChange("third_name", e)}
                />
              
              </div>
}
            </div>
          </div>
          <div className="label-wrapper">
          <div className="col-group">
          {data?.activityRegister?.label?.firstDetail &&
              <div className="col-dt-4 label-1">
            
                <input
                  type="text"
                  placeholder={data?.activityRegister?.label?.firstDetail}
                  // value={firstname}
                 
                  onChange={(e) => handleFieldChange("first_detail", e)}
                />
              
              </div>
}
{data?.activityRegister?.label?.secondDetail &&
              <div className="col-dt-4 label-1">
             
                <input
                  type="text"
                  placeholder={data?.activityRegister?.label?.secondDetail}
                  // value={firstname}
                 
                  onChange={(e) => handleFieldChange("second_detail", e)}
                />
                
              </div>
}
{data?.activityRegister?.label?.thirdDetail &&
              <div className="col-dt-4 label-1">
             
                <input
                  type="text"
                  placeholder={data?.activityRegister?.label?.thirdDetail}
               
                  onChange={(e) => handleFieldChange("third_detail", e)}
                />
              
              </div>
}
            </div>
          </div>
      

          <div className="label-wrapper">
            <div className="submit-btn-wrapper">
              <div className="submit-btn">
              {data?.activityRegister?.label?.enableSubmit &&
                <button className="cta-btn" type="submit" disabled={isLoading}>{isLoading ? string_translation(stringTranslations, "loading", locale) : string_translation(stringTranslations, "submit", locale)}</button>
              }
                </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  )

}

export default function RegisterActivity({data, locale}) {


  const { stringTranslations, site } = useStaticQuery(graphql`
    query {
      stringTranslations {
        newsroom_form_smalltitle_ar:translateString(language: AR, string: "تواصل مع المركز الإعلامي")
        newsroom_form_smalltitle_en:translateString(language: EN, string: "Contact Media Center")
        newsroom_form_bigtitle_ar:translateString(language: AR, string: "NEWSROOM FORM TITLE 2")
        newsroom_form_bigtitle_en:translateString(language: EN, string: "NEWSROOM FORM TITLE 2")

        fname_ar:translateString(language: AR, string: "FIRST NAME")
        fname_en:translateString(language: EN, string: "FIRST NAME")
        lname_ar:translateString(language: AR, string: "LAST NAME")
        lname_en:translateString(language: EN, string: "LAST NAME")
        email_ar:translateString(language: AR, string: "EMAIL")
        email_en:translateString(language: EN, string: "EMAIL")
        phone_ar:translateString(language: AR, string: "PHONE")
        phone_en:translateString(language: EN, string: "PHONE")
        media_outlet_ar:translateString(language: AR, string: "MEDIA OUTLET")
        media_outlet_en:translateString(language: EN, string: "MEDIA OUTLET")
        country_ar:translateString(language: AR, string: "COUNTRY")
        country_en:translateString(language: EN, string: "COUNTRY")
        role_ar:translateString(language: AR, string: "الصفة")
        role_en:translateString(language: EN, string: "Role")
        msg_ar:translateString(language: AR, string: "Enquiry/Message")
        msg_en:translateString(language: EN, string: "Enquiry/Message")
        submit_ar:translateString(language: AR, string: "SUBMIT")
        submit_en:translateString(language: EN, string: "SUBMIT")
        try_again_ar:translateString(language: AR, string: "TRY AGAIN")
        try_again_en:translateString(language: EN, string: "TRY AGAIN")
        submission_error_ar:translateString(language: AR, string: "SUBMISSION ERROR")
        submission_error_en:translateString(language: EN, string: "SUBMISSION ERROR")
        loading_ar:translateString(language: AR, string: "LOADING")
        loading_en:translateString(language: EN, string: "LOADING")



        activity_name_ar:translateString(language: AR, string: "فعالية رياض")
        activity_name_en:translateString(language: EN, string: "Riyad’s Activity")

        activity_details_1_ar:translateString(language: AR, string: "الفعالية مقامة للأطفال من 6 إلى 10 سنوات")
        activity_details_1_en:translateString(language: EN, string: "Children ages 6-10 are allowed.")


        activity_details_2_ar:translateString(language: AR, string: "الفعالية ستقام باللغة العربية فقط")
        activity_details_2_en:translateString(language: EN, string: "The activity will be available in Arabic language only.")


        parent_name_ar:translateString(language: AR, string: "اسم ولي الأمر ")
        parent_name_en:translateString(language: EN, string: "Parent Name ")

        phone_number_ar:translateString(language: AR, string: "رقم الجوال ")
        phone_number_en:translateString(language: EN, string: "Phone Number")

        email_parent_ar:translateString(language: AR, string: "البريد الإلكتروني ")
        email_parent_en:translateString(language: EN, string: "Email")

        event_day_ar:translateString(language: AR, string: "اختيار يوم الفعالية ")
        event_day_en:translateString(language: EN, string: "Choose the event day")

        day2_ar:translateString(language: AR, string: "اليوم الثاني (16 نوفمبر 2023) من الساعة 5 مساءً وحتى 7 مساءً ")
        day2_en:translateString(language: EN, string: "Second Day (16 November 2023) from 5 pm to 7 pm ")

        day3_ar:translateString(language: AR, string: "اليوم الثالث (18 نوفمبر 2023) من الساعة 5 مساءً وحتى 7 مساءً ")
        day3_en:translateString(language: EN, string: "Third Day (18 November 2023) from 5 pm to 7 pm ")

        number_of_child_ar:translateString(language: AR, string: "عدد الأطفال ")
        number_of_child_en:translateString(language: EN, string: "Number of Children ")

        first_child_name_ar:translateString(language: AR, string: "اسم الطفل الأول ")
        first_child_name_en:translateString(language: EN, string: "First Child’s Name")

        first_child_age_ar:translateString(language: AR, string: "عمر الطفل الأول ")
        first_child_age_en:translateString(language: EN, string: "First Child’s Age ")

        second_child_name_ar:translateString(language: AR, string: "اسم الطفل الثاني ")
        second_child_name_en:translateString(language: EN, string: "Second Child’s Name ")

        second_child_age_ar:translateString(language: AR, string: "عمر الطفل الثاني ")
        second_child_age_en:translateString(language: EN, string: "Second Child’s Age ")

        third_child_name_ar:translateString(language: AR, string: "اسم الطفل الثالث ")
        third_child_name_en:translateString(language: EN, string: "Third Child’s Name ")

        third_child_age_ar:translateString(language: AR, string: "عمر الطفل الثالث ")
        third_child_age_en:translateString(language: EN, string: "Third Child’s Age")

 
      }
      site {
        siteMetadata {
          formSubmissionUrl
        }
      }
    }
  `)

  return (
    <>
      <section className="get-in-touch register-act-section" style={{backgroundImage:`url(${data?.activityRegister?.label?.backgroundImage?.localFile?.publicURL})`}}>
        <div className="container">
          <div className="form-wrapper register-activity">
           
            <div className="activity-big-title h2">{data?.title}</div>
            <div className="activity-small-title " dangerouslySetInnerHTML={createMarkup(data?.content)}></div>
            <div className="get-in-touch-form-wrapper">
              <Cf7FormWrapper siteUrl={site.siteMetadata.formSubmissionUrl} formId={data?.activityRegister?.contactForm}>
                <Form stringTranslations={stringTranslations}  data={data}/>
              </Cf7FormWrapper>
            </div>
            <div>
             
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
