import * as React from 'react'
import Getintouch from '../components/GetinTouch'
import Seo from '../components/seo'
import Layout from '../components/layout'
import WhoWeAre from '../components/WhoWeAre'
import { graphql } from "gatsby"
import { useContext } from "react"
import { TranslateUrlsContext } from "gatsby-plugin-translate-urls"
import RegisterActivity from '../components/RegisterActivity'
export default function ActivityRegister(pageData) {
  const data = pageData?.data
  const {
    locale, // ar
  } = useContext(TranslateUrlsContext)
  let seo = pageData?.pageContext?.node?.seo
  let translatedURI = pageData?.pageContext?.node?.translations[0]?.uri
    return (
        <>
        <Seo data={seo}/>
        <Layout translatedURI={translatedURI}>
       
        
         
          <RegisterActivity data={data?.wpPage} locale={locale}/>
        </Layout>
        </>
    )
}

export const pageQuery = (graphql` query newsroomData($databaseId:Int) {
    
  wpPage(databaseId: {eq: $databaseId}) {
    id
    title
    content
    pageSubtitle {
      fieldGroupName
      subtitle
    }
    content
    featuredImage {
      node {
        localFile {
          publicURL
        }
      }
    }
    pageDescription {
      pageDescription
    }
    activityRegister {
    
      
      contactForm
      label{
        name
        phone
        email
        eventDay
        numberOfPerson
        firstName
        secondName
        thirdName
        firstDetail
        secondDetail
        thirdDetail
        eventDayList{
          eventDay
        }
        numberOfPersonList{
          numberOfPerson
        }
        enableSubmit
        backgroundImage{
          localFile{
            publicURL
          }
        }
      }
    }
  }
}`);